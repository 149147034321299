import Icon from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import React, { useState } from 'react';
import DeleteIcon from '~/assets/svg/icon-trash.svg?react';
import CustomSelect from '~/components/shared/CustomSelect/CustomSelect';
import { BULK_ACTION_EVENTS, MAX_SELECTED_PRODUCTS } from '~/helpers/catalogue';

import BulkEditionModal from '../BulkEditionModal/BulkEditionModal';
import BulkItemsUpdater from '../BulkItemsUpdater/BulkItemsUpdater';
import './BulkEdition.scss';

const bulkEditOptions = [
  {
    value: BULK_ACTION_EVENTS.EDIT_STOCK,
    label: 'Modifier le stock',
  },
  {
    value: BULK_ACTION_EVENTS.ADD_LABEL_AFFAIRE,
    label: 'Passer en Label Affaire',
  },
  {
    value: BULK_ACTION_EVENTS.REMOVE_LABEL_AFFAIRE,
    label: 'Retirer la Label Affaire',
  },
  {
    value: BULK_ACTION_EVENTS.EDIT_INSPIRATION,
    label: 'Modifier les sélections',
  },
];

function BulkEdition({ searchParams, selectedProductIds, updateSelectedProductIds, currentPage, currentLimit, productsList, totalItems }) {
  const [bulkAction, setBulkAction] = useState(null);
  const [selectValue, setSelectValue] = useState(null);

  return (
    <>
      <Row className='bulk-edition-row' gutter={[12, 12]}>
        <Col xs={24} sm={8}>
          <div className='bulk-items-wrapper'>
            <BulkItemsUpdater
              searchParams={searchParams}
              selectedProductIds={selectedProductIds}
              updateSelectedProductIds={updateSelectedProductIds}
              currentPage={currentPage}
              currentLimit={currentLimit}
              productsList={productsList}
              totalItems={totalItems}
            />
          </div>
        </Col>
        {selectedProductIds.length > 0 && selectedProductIds.length <= MAX_SELECTED_PRODUCTS && (
          <Col xs={{ span: 24, offset: 0 }} sm={{ span: 14, offset: 2 }}>
            <div className='bulk-action-wrapper'>
              <CustomSelect
                value={selectValue}
                className='select-bulk-action'
                placeholder='Sélectionner une action'
                onChange={(bulkAction) => {
                  setSelectValue(bulkAction);
                  setBulkAction(bulkAction);
                }}
                options={bulkEditOptions}
              />
              <Button
                type='text'
                className='btn-icon'
                size='large'
                icon={<Icon component={DeleteIcon} />}
                onClick={() => setBulkAction(BULK_ACTION_EVENTS.DELETE)}
              />
              <Button type='primary' onClick={() => setBulkAction(BULK_ACTION_EVENTS.ACTIVATE)}>
                Publier
              </Button>
            </div>
          </Col>
        )}
      </Row>
      {bulkAction !== null && (
        <BulkEditionModal
          handleReset={() => {
            setSelectValue(null);
            setBulkAction(null);
          }}
          searchParams={searchParams}
          bulkActionEvent={bulkAction}
          selectedProductIds={selectedProductIds}
        />
      )}
    </>
  );
}

export default BulkEdition;
