import { TreeSelect } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { AD_DETAILS_API_IDS } from '~/helpers/adDetails';
import { HANDLE_CHANGE } from '~/store/reducers/products';

function Inspirations({ dispatch, component, inspirationsValue }) {
  const { key, helptext, choices } = component;

  const filteredInspirations = inspirationsValue.filter((id) => choices.find((univers) => univers.choices.find((inspiration) => inspiration.id === id)));

  const treeData = choices.map((univers) => ({
    title: univers.name,
    value: univers.value,
    checkable: false,
    children: univers.choices.map((inspiration) => ({
      title: inspiration.name,
      value: inspiration.id,
      checkable: filteredInspirations.length < 2 || filteredInspirations.includes(inspiration.id),
    })),
  }));

  return (
    <>
      <div className='form-item-header'>
        <p className='sub2'>Sélections</p>
        {helptext && <p className='subtext'>{helptext}</p>}
      </div>
      <TreeSelect
        treeData={treeData}
        treeCheckable
        treeDefaultExpandAll={true}
        virtual={false}
        placeholder={'Sélectionnez une ou plusieurs sélection(s)'}
        value={filteredInspirations}
        onChange={(inspirations) => {
          dispatch({
            type: HANDLE_CHANGE,
            payload: {
              input_key: key,
              value: inspirations,
            },
          });
        }}
        filterTreeNode={(input, treeNode) =>
          treeNode.children && treeNode.children.some((inspiration) => inspiration.checkable && inspiration.title.toLowerCase().includes(input.toLowerCase()))
        }
      />
    </>
  );
}

export default connect((state) => {
  const { form_fields } = state.products;
  const inspirationsValue = form_fields[AD_DETAILS_API_IDS.PROMOTION.INSPIRATIONS];
  return {
    inspirationsValue,
  };
})(Inspirations);
