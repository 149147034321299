import { App, Button, Flex, Table } from 'antd';
import React, { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { getOrdersPickingInfo } from '~/services/api.calls';

import './PickingRoute.scss';

export default function PickingRoute({ merchantId, orderIds }) {
  const { notification } = App.useApp();

  const [isLoading, setIsLoading] = useState(false);
  const pickingRouteRef = useRef();
  const [pickingRouteInfo, setPickingRouteInfo] = useState([]);

  const handlePrint = useReactToPrint({
    content: () => pickingRouteRef.current,
  });

  const printPickingRoute = () => {
    setIsLoading(true);
    getOrdersPickingInfo(
      merchantId,
      orderIds,
      (data) => {
        setIsLoading(false);
        setPickingRouteInfo(
          data.map((order) => ({
            ...order,
            items: order.items.map((item) => ({
              ...item,
              client_name: order.client_name,
            })),
          }))
        );
        handlePrint();
      },
      (error) => {
        setIsLoading(false);
        notification.open({
          className: 'notification-dialogue-error',
          message: 'Erreur lors de la génération de la feuille de picking',
          description: error.toString(),
          placement: 'topRight',
        });
      }
    );
  };
  return (
    <>
      <Button type='primary' disabled={isLoading} onClick={printPickingRoute}>
        Imprimer ma feuille de picking
      </Button>
      <div className='hidden'>
        <div ref={pickingRouteRef} className='picking-route-wrapper'>
          {pickingRouteInfo.map((order, index) => (
            <Flex key={index} vertical gap={16}>
              <Flex align='center' gap={8}>
                <p className='bold' style={{ width: '290px' }}>
                  Commande {order.id_number}
                </p>
                <p>
                  {order.items.length} produit{order.items.length > 1 ? 's' : ''}
                </p>
              </Flex>
              <Table
                columns={[
                  {
                    title: 'Emplacement',
                    dataIndex: 'storage',
                    key: 'storage',
                    className: 'storage',
                    width: 150,
                  },
                  {
                    title: 'Qté',
                    dataIndex: 'quantity',
                    key: 'quantity',
                    className: 'quantity',
                    width: 10,
                  },
                  {
                    title: 'SKU',
                    dataIndex: 'sku',
                    key: 'sku',
                    className: 'sku',
                    width: 100,
                  },
                  {
                    title: 'Produit',
                    dataIndex: 'name',
                    key: 'name',
                    className: 'name',
                    width: 150,
                  },
                  {
                    title: 'Client.e',
                    dataIndex: 'client_name',
                    key: 'client_name',
                    className: 'client_name',
                    width: 150,
                  },
                ]}
                dataSource={order.items}
                pagination={false}
              />
            </Flex>
          ))}
        </div>
      </div>
    </>
  );
}
